<template>
  <div class="Admincss">
    <el-container class="homeContainer">
      <el-container>
        <el-header height="80px">
          <div class="headerTitle">学生学情跟踪系统</div>
          <div class="headerTitle">Hi~ 超级管理员</div>
          <div class="headerOutBtn" @click="handleClickLoginOut">
            <i class="iconfont icon-zhengque" style="margin-top: 3px"></i>
            <div class="outBtn">退出</div>
          </div>
        </el-header>
      </el-container>
    </el-container>
    <Usermanage class="Usercss"></Usermanage>
  </div>
</template>

<script>
import Usermanage from "../userManagement/usermanage.vue";
export default {
  methods: {
    //退出
    handleClickLoginOut() {
      window.sessionStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
  },
  components: {
    Usermanage,
  },
};
</script>
<style lang="scss" scoped>
.homeContainer {
  .el-header {
    padding: 0;
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .headerTitle {
      width: 160px;
      height: 20px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #266eff;
      line-height: 75px;
      margin-left: 40px;
    }
    .headerOutBtn {
      display: flex;
      justify-content: space-between;
      width: 55px;
      margin-top: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 75px;
      cursor: pointer;
      &:hover {
        color: #266eff;
      }
    }
  }
  .el-main {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    height: 100%;
    position: relative;
    .mainCopyRight {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c0c4cc;
    }
  }
}
.Usercss {
  margin-left: 10%;
  margin-top: 1%;
}
</style>