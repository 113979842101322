<template>
  <div class="usermanage">
    <div class="usermanageheader">
      <div @click="chakan">用户管理</div>
    </div>
    <div class="usermanagedata">
      <div class="usermanageclass" @click="accountadd">新增</div>
      <div class="studentMain">
        <div class="mainTableBox">
          <el-table
            style="width: 100%"
            :header-cell-style="{
              background: '#F2F6FC',
              'text-align': 'center',
            }"
            :cell-style="{ 'text-align': 'center' }"
            :data="
              userdata.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
            "
          >
            <el-table-column label="序号" prop="num" width="180px">
            </el-table-column>
            <el-table-column label="姓名" prop="username"> </el-table-column>
            <el-table-column label="账号" prop="mobile"> </el-table-column>
            <el-table-column label="密码" prop="show_pwd"> </el-table-column>
            <el-table-column label="身份" prop="position"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scopev">
                <div class="btnBox" v-show="scopev.row.position != '管理员'">
                  <div class="removeBtn" @click="Editaccount(scopev.row)">
                    编辑
                  </div>
                  <div class="editBtn" @click="gethome(scopev.row)">管理</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="mainPageBox">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="userdata.length"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 修改页面 -->
    <el-dialog
      title="编辑老师信息"
      :visible.sync="editStudentDialogAdmin"
      width="53%"
      class="studentDialog"
    >
      <span>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" class="ruleFormwinth"></el-input>
          </el-form-item>

          <el-form-item label="性别" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="账号" prop="account">
            <el-input
              v-model="ruleForm.account"
              autocomplete="off"
              class="ruleFormwinth"
            ></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="pass">
            <el-input
              type=""
              v-model="ruleForm.pass"
              autocomplete="off"
              class="ruleFormwinth"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitFormed('ruleForm')"
              >保存</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
    <!-- 新增页面 -->
    <el-dialog
      title="新增学生"
      :visible.sync="increased"
      width="53%"
      class="studentDialog"
    >
      <span>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" class="ruleFormwinth"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="身份" class="radiocss" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择">
              <el-option
                v-for="item in identity"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="ruleForm.account"
              autocomplete="off"
              class="ruleFormwinth"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input
              type=""
              v-model="ruleForm.pass"
              autocomplete="off"
              class="ruleFormwinth"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import "../../iconfont/iconfont.css";
import { Usersdata, AdminUseradd } from "../../api/api";
export default {
  data() {
    //验证函数
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //身份证号码的验证
    let idCardValidity = (rule, code, callback) => {
      var city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      var tip = "";
      var pass = true;

      if (code == "") {
      } else if (
        !code ||
        !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/i.test(code)
      ) {
        console.log(code);
        tip = "身份证号格式错误";
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        tip = "地址编码错误";
        pass = false;
      } else {
        // 18位身份证需要验证最后一位校验位
        if (code.length === 18) {
          code = code.split("");
          // ∑(ai×Wi)(mod 11)
          // 加权因子
          var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          // 校验位
          var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
          var sum = 0;
          var ai = 0;
          var wi = 0;
          for (var i = 0; i < 17; i++) {
            ai = code[i];
            wi = factor[i];
            sum += ai * wi;
          }
          var last = parity[sum % 11];
          if (parity[sum % 11] != code[17]) {
            tip = "校验位错误";
            pass = false;
          }
        }
      }
      if (!pass) {
        callback(new Error(tip));
      } else {
        callback();
      }
      // if (!pass) alert(tip)
      // return pass
    };
    return {
      userdata: [], //列表的数据

      pageSize: 11, // 页容量

      currentPage: 1, // 当前选中页码

      editStudentDialogAdmin: false, //编辑页面的隐藏

      userid: "", //老师的id

      identity: [
        {
          label: "企业",
          value: 1,
        },
        // {
        //   label: "管理员",
        //   value: 2,
        // },
        {
          label: "老师",
          value: 3,
        },
      ], //身份

      //表单数据
      ruleForm: {
        name: "", //姓名
        resource: "", //性别
        account: "", //账号
        pass: "", //密码
        region: "", //身份
      },

      //验证规则
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        region: [
          {
            required: true,
            message: "请选择身份",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 ",
            trigger: "blur",
          },
        ], //账号
        pass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 10,
            message: "长度在 6 到 10 个字符",
            trigger: "blur",
          },
        ], //密码
      },

      //新增的参数
      increased: false,

      //新增的表单数据
      //表单数据
      ruleFormdata: {
        name: "", //姓名
        region: "", //编组
        date1: "", //日期
        resource: "", //性别
        identitycard: "", //身份证号
        account: "", //账号
        pass: "", //密码
        checkPass: "", //确认密码
      },
    };
  },
  mounted() {
    this.gettingdata();
  },
  methods: {
    //查看
    chakan() {
      console.log("跳转到超级管理员");
      this.$router.push("/Admin");
    },

    //获取数据
    gettingdata() {
      Usersdata(2).then((res) => {
        this.userdata = res.data.data;
        console.log(this.userdata);
      });
    },
    //翻页
    currentChange(val) {
      this.currentPage = val;
    },
    //管理老师的账号
    Editaccount(val) {
      console.log("修改账号");
      console.log(val);
      this.ruleForm.name = val.username;
      this.ruleForm.account = val.mobile;
      this.ruleForm.pass = val.show_pwd;
      this.ruleForm.resource = val.sex;
      this.userid = val.id;
      console.log(this.ruleForm);
      this.editStudentDialogAdmin = true;
    },
    submitFormed(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          AdminUseradd(this.ruleForm, this.userid).then((res) => {
            console.log(res);
            if (res.data.code == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.go(0);
            }
          });
        } else {
          this.$message({
            message: "请填写完整数据",
            type: "warning",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //进入老师的页面
    gethome(val) {
      // console.log(val.id);
      window.sessionStorage.setItem("userId", val.id);
      this.$router.push("/home");
    },
    //新增账号
    accountadd() {
      this.increased = true;
    },
    //确定新增
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          AdminUseradd(this.ruleForm).then((res) => {
            console.log(res);
            if (res.data.code == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$router.go(0);
            }
          });
        } else {
          this.$message({
            message: "请填写完整数据",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.usermanageheader {
  background-color: #ffffff;
  width: 1590px;
  height: 70px;
  // text-align: center;
  line-height: 70px;
  div {
    margin-left: 50px;
    font-size: 18px;
    color: #266eff;
    font-weight: 800;
  }
}
.usermanagedata {
  background-color: #ffffff;
  margin-top: 35px;
  width: 1590px;
  .usermanageclass {
    margin-left: 95%;
    padding-top: 25px;
    font-size: 18px;
    color: #266eff;
    font-weight: 700;
    cursor: pointer;
    .classTitle {
      text-align: center;
      line-height: 40px;
      padding-right: 10px;
    }
  }
}
.studentMain {
  margin-top: 30px;
  width: 95%;
  margin-left: 35px;
  .mainTableBox {
    width: 100%;
    height: 600px;
    .btnBox {
      display: flex;
      width: 100%;
      justify-content: center;
      .removeBtn {
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        &::after {
          content: "";
          width: 1px;
          height: 14px;
          background: #e4e7ed;
          position: absolute;
          right: -10px;
          top: 3px;
        }
      }
      .editBtn {
        cursor: pointer;
        // font-size: 16px;
      }
    }
  }
  .mainPageBox {
    width: 100%;
    text-align: center;
  }
}
//表单验证框的样式
.studentDialog {
  /deep/ .el-dialog {
    .el-dialog__body {
      span {
        .el-form {
          position: relative;
          left: 15%;
        }
        .ruleFormwinth {
          // background-color: red;
          width: 50%;
        }
      }
    }
  }
}
</style>